import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: (): Promise<any> => import ("@/views/Login.vue"),
    meta: {
      guest: true
    }
  },
  {
    path:"/TerminiDiServizio",
    name: "TerminiDiServizio",
    component: (): Promise<any> => import ("@/views/TerminiDiServizio.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/",
    redirect: "/home",
    component: (): Promise<any> => import ("@/layouts/DashboardLayout.vue"),
    children: [
      {
        path: "/home",
        name: "Home",
        component: (): Promise<any> => import ("@/views/Home.vue"),
        meta: {
          index: "1",
          requiresAuth: true
        }
      },
      {
        path: "/campi",
        name: "Gestione campi",
        component: (): Promise<any> => import ("@/views/Fields.vue"),
        meta: {
          index: "2",
          requiresAuth: true
        }
      },
      {
        path: "/addField/:id",
        name: "Aggiungi Campo",
        component: (): Promise<any> => import ("@/views/AddField.vue"),
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "/subscriptions",
        name: "Abbonamenti",
        component: (): Promise<any> => import ("@/views/Subscriptions.vue"),
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "/subscriptions/:id",
        name: "Abbonamenti",
        component: (): Promise<any> => import ("@/views/Subscriptions.vue"),
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "/EditField/:id_struttura/:id",
        name: "Modifica Campo",
        component: (): Promise<any> => import ("@/views/EditField.vue"),
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "/servizi",
        name: "Gestione servizi",
        component: (): Promise<any> => import ("@/views/Services.vue"),
        meta: {
          index: "3",
          requiresAuth: true
        }
      },
      {
        path: "/addServizi",
        name: "Aggiungi servizio",
        component: (): Promise<any> => import ("@/views/AddService.vue"),
        meta:{
          requiresAuth: true
        }
      },
      {
        path: "/discounts",
        name: "Gestione sconti",
        component: (): Promise<any> => import ("@/views/Discounts.vue"),
        meta: {
          index: "4",
          requiresAuth: true
        }
      },
      {
        path: "/addDiscount",
        name: "Aggiungi sconto",
        component: (): Promise<any> => import ("@/views/AddDiscount.vue"),
        props: router => ({ query: router.query.filter}),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/structure",
        name: "Info struttura",
        component: (): Promise<any> => import ("@/views/Structure.vue"),
        meta:{
          index: "5",
          requiresAuth: true
        }
      },
      {
        path: "/modStructure",
        name: "Modifica struttura",
        component: (): Promise<any> => import ("@/views/ModStructure.vue"),
        meta:{
          requiresAuth: true
        }
      },
      {
        path:"/error",
        name: "Errore",
        component: (): Promise<any> => import ("@/components/ErrorPage.vue")
      },
      {
        path:"/prenotazioniAnnullate",
        name: "Prenotazioni Annullate",
        component: (): Promise<any> => import ("@/views/CancelledReservations.vue"),
        meta:{
          index: "6",
          requiresAuth: true
        }
      },
      {
        path:"/sommario",
        name: "Sommario",
        component: (): Promise<any> => import ("@/views/Summary.vue"),
        meta:{
          index: "7",
          requiresAuth: true
        }
      },
      {
        path:"/entrateuscite",
        name: "EntrateUscite",
        component: (): Promise<any> => import ("@/views/EntrateUscite.vue"),
        meta:{
          index: "8",
          requiresAuth: true
        }
      },
      {
        path:"/tornei",
        name: "Tornei",
        component: (): Promise<any> => import ("@/views/Tournaments.vue"),
        meta:{
          index: "9",
          requiresAuth: true
        }
      },
      {
        path:"/aggiungiTorneo",
        name: "Aggiungi Torneo",
        component: (): Promise<any> => import ("@/views/AddTournament.vue"),
        meta:{
          requiresAuth: true
        }
      },
    ]
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
  {
    path:"/termini&Condizioni",
    name: "Termini e Condizioni",
    component: (): Promise<any> => import ("@/views/TerminiTxt.vue"),
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {

  if (to.matched.some(x => x.meta.requiresAuth)) {
    if (sessionStorage.getItem("access_token") == null) {
      next({
         path: "/login",
         params: { nextUrl: to.fullPath }
      });
    } else {
      if(sessionStorage.getItem('termini') != "true" && to.name != "TerminiDiServizio"){
        next({
          path: "/TerminiDiServizio"
        });
      } else {
        //TODO: Perform auth and go to dashboard
        next();
      }

    }

  } else if (to.matched.some(x => x.meta.guest)) {

    if (sessionStorage.getItem("access_token") == null) {
      next();
    } else {
      next({
        path: "/",
      });
      //TODO: Perform auth and go to dashboard

    }

  } else {
    next();
  }

});

export default router;
