// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import element from "element-ui";
import locale from "element-ui/lib/locale/lang/it";
import "./theme.scss";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// Import styles
import VueScheduler from 'vue-calendar-kampetti';
import "../Calendar.sass";

//import 'v-calendar-scheduler/lib/main.css';
Vue.config.productionTip = false;
require('dotenv').config()

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
Vue.use(VueScheduler,{locale:"it"})
Vue.use(element, {locale});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
